@import 'global/z-indexes';

@mixin napapijri-reveal {
  .reveal,
  .reveal-modal {
    overflow-y: auto;
    z-index: z-index(reveal);

    .close-button {
      @include close-button;
      @include close-button-size(small);
      text-decoration: none;

      &:hover {
        color: $medium-gray;
      }
    }
  }
}
